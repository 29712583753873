import React, { Component } from 'react';

import Button from '../../components/LinkButton/button';
import Uploader from '../../components/Uploader';

import styles from './styles.module.less';

import { init, login, isAdmin, onUserContextCreated, close } from '../../lib/identity';

export default class Upload extends Component {
   state = {
      isAdmin: false,
      fileInfo: null,
      error: false
   };

   componentDidMount() {
      onUserContextCreated(this.handleLogin);
      init();
   }

   handleLogin = () => {
      let admin = isAdmin();
      close();
      this.setState({ isAdmin: admin, error: !admin });
   };

   handleClickLogin = () => {
      login();
   };

   handleUploadComplete = fileInfo => {
      this.setState({ fileInfo });
   };

   renderLogin() {
      const { error } = this.state;
      return (
         <div className={styles.login}>
            <h1>Please log in</h1>
            {!!error && (
               <p>
                  <strong>Administrator role required.</strong>
               </p>
            )}
            <div className={styles.action}>
               <Button label="Log in" onClick={this.handleClickLogin} />
            </div>
         </div>
      );
   }

   renderUpload() {
      const { fileInfo } = this.state;
      let url = '';
      if (fileInfo) {
         url = `${fileInfo.cdnUrl}${fileInfo.name}`;
      }
      return (
         <div className={styles.upload}>
            <h1>Upload file</h1>
            <div className={styles.action}>
               <Uploader onUploadComplete={this.handleUploadComplete} data-public-key="6ffbab390389e8747e2c" />
               {!!url && (
                  <p>
                     <a href={url}>{`${fileInfo.cdnUrl}${fileInfo.name}`}</a>
                  </p>
               )}
            </div>
         </div>
      );
   }

   render() {
      const { isAdmin } = this.state;
      return (
         <section className={styles.Upload}>
            {!isAdmin && this.renderLogin()}
            {!!isAdmin && this.renderUpload()}
         </section>
      );
   }
}
